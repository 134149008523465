
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import analytics from '../../../../mixins/analytics';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { EChartsOption, LegendOption } from 'echarts/types/dist/shared';

const ECharts = (window as any).echarts || undefined;
if (!ECharts) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericLineChart',
  components: { NoChartData, EditModuleBtn },
  props: [
    'loading',
    'metrics',
    'metricsNames',
    'activeClients',
    'activeImpressions',
    'activeCampaigns',
    'activeOrders',
    'sectionConfig',
    'componentConfig',
    'title',
    'subTitle',
    'icon',
    'chartType',
    // 'commonTweaks',
    'dataSource',
    'dataKey',
    'theme',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
    'loadingFromLs',
    'dynamicLineKeys',
    'daterange',
    'withTracking',
  ],
  data: (): {
    chosenMetric: any;
    toggle_chart: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    canvasWidth: string;
    canvasHeight: string;
    legendSelected: LegendOption | null;
    selectedLine: string | null;
    chartData: any;
  } => ({
    chosenMetric: null,
    toggle_chart: '',
    chartInstance: undefined,
    canvasWidth: 'auto',
    canvasHeight: '310px',
    legendSelected: null,
    selectedLine: null,
    chartData: null,
  }),
  mixins: [analytics],
  watch: {
    metrics: {
      handler(): void {
        if (!this.loading) {
          this.$nextTick(() => {
            this.setChartData(this.isNewHome ? this.metrics?.[3].name : this.metrics?.[0]?.title);
          });
        }
      },
    },
    chartData: {
      immediate: true,
      handler(): void {
        this.initChart();
        if (
          this.chartData?.length === 0 &&
          this.componentConfig.hideIfNoData &&
          !this.$store.state.layoutEditor.editMode
        ) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
    '$store.state.customer.theme': 'initChart',
    // custimaztion changes
    showOneLine: {
      handler(): void {
        this.initChart();
      },
    },
    chartColors: {
      handler(): void {
        this.initChart();
      },
    },
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  mounted() {
    if (this.loadingFromLs && !this.loading) {
      this.setChartData(this.metrics?.[3]?.name);
    }
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    if (this.componentConfig.selectedLineSeriesKey) {
      this.selectedLine = this.componentConfig.selectedLineSeriesKey;
    } else if (this.componentConfig.lineSeriesKeys?.length > 0) {
      this.selectedLine = this.componentConfig.lineSeriesKeys[0];
    }
    this.initChart();
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    formattedDaterange(): string {
      let formattedRange = '';
      switch (this.daterange) {
        case 'thisyear':
          formattedRange = 'Last 365 Days';
          break;
        case 'thisweek':
          formattedRange = 'Last 7 Days';
          break;
        case 'thismonth':
          formattedRange = 'Last 30 Days';
          break;
      }
      return formattedRange;
    },
    isNewHome(): boolean {
      return this.componentConfig.dataSource === 'HOME.FullMetric';
    },
    getInterval(): number {
      if (this.chartData[0].length > 7) {
        return 2;
      } else return 1;
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.loadingFromLs || (this.chosenMetric && this.chartData[0]?.length > 0);
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasStyle(): any {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
    sourceData() {
      let data;
      // check for indivdual data
      const hasIndivdualCampaignData = this.tweaks?.includes('indivdual');
      if (hasIndivdualCampaignData) {
        data = utils.adDataForKey(this, `${this.dataSource}Campaign`);
        if (!Array.isArray(data)) {
          data = utils.adDataForKey(this, this.dataSource);
        }
      } else {
        data = utils.adDataForKey(this, this.dataSource);
      }
      return data;
    },
    limitChartsEnoughData(): boolean {
      if (!Array.isArray(this.sourceData)) {
        return false;
      }
      return this.sourceData.length === 1;
    },
    lineSeriesKeys(): string[] {
      let lineSeriesKeys = this.componentConfig.dynamicLineKeys
        ? this.dynamicLineKeys
        : this.componentConfig.lineSeriesKeys;
      if (!Array.isArray(lineSeriesKeys)) {
        if (typeof lineSeriesKeys === 'string') {
          lineSeriesKeys = [lineSeriesKeys];
        } else {
          lineSeriesKeys = [];
        }
      }
      const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
      if (hideEmptySeriesKeys) {
        const rows = this.sourceData;
        // remove keys that don't have any data
        hideEmptySeriesKeys.forEach((k: string) => {
          if (!rows.find(x => !!x[k])) {
            lineSeriesKeys = lineSeriesKeys.filter(x => x !== k);
          }
        });
      }

      // order to make selected line last so non-selected dont overlap
      // we don't need it on order's page bc of different data format
      if (this.isNewHome) {
        if (lineSeriesKeys.length > 1 && this.selectedLine) {
          const keys: string[] = [this.selectedLine];
          lineSeriesKeys.forEach((key: string) => {
            if (key !== this.selectedLine) keys.push(key);
          });
          lineSeriesKeys = keys;
        }
      }
      return lineSeriesKeys;
    },
    chartConfig(): EChartsOption {
      let lineColor, textColor;
      if (this.Theme.config.lineCharts?.style === 'minimal') {
        lineColor = '#fff';
        textColor = '#000';
      } else {
        lineColor = '#000';
        textColor = lineColor;
      }
      if (this.isExporting) {
        lineColor = '#333';
      }

      let colorset = this.colorset;
      if (this.componentConfig.useChartColorIndex > -1) {
        colorset = [this.colorset[this.componentConfig.useChartColorIndex]];
      }

      const currentStyle = {
        grid: {
          containLabel: true,
          left: 'center',
          bottom: '10%',
          top: '10%',
          width: '92%',
          height: 'auto',
        },
      };

      const cfg: EChartsOption = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          textStyle: {
            color: '#3E5769',
          },
          show: true,
        },
        title: {
          show: false,
          textStyle: {
            color: textColor,
            fontFamily: 'sans-serif',
          },
        },
        grid: this.isExporting
          ? {
              containLabel: true,
              left: this.componentConfig.canvasPosition?.left || 10,
              top: this.componentConfig.canvasPosition?.top || 10,
              right: this.componentConfig.canvasPosition?.right || 10,
              bottom: this.componentConfig.canvasPosition?.bottom || 10,
              height: this.componentConfig.canvasPosition?.height,
              width: this.componentConfig.canvasPosition?.width,
            }
          : currentStyle.grid,
        xAxis: {
          show: this.isExporting || this.Theme.config.lineCharts?.xAxis?.show !== false,
          data: this.chartData?.[0],
          type: 'category',
          boundaryGap: this.chartData?.[0]?.length > 6,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#DFEBF4',
              type: 'dashed',
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          axisTick: {
            show: this.isExporting,
            alignWithLabel: false,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          max: parseInt(this.componentConfig.xAxisMaxItems) || 'dataMax',
          axisLabel: {
            show: true,
            color: textColor,
            interval: 'auto',
          },
        },
        color: colorset,
      };

      if (Array.isArray(this.chartData) && this.chartData.length) {
        // line series
        cfg.series = this.lineSeriesKeys.reduce((lines: object[], key: string, index: number) => {
          const line = {
            type: 'line',
            selectMode: false,
            animation: !this.isExporting,
            name: this.getLegendKeyName(key),
            yAxisIndex: index,
            data: this.chartData[index + 1],
            smooth: true,
            // symbol: this.chartData[0].length > 1 ? 'none' : 'emptyCircle',
            symbol: 'circle',
            symbolSize: 1,
            showSymbol: true,
            zlevel: key === this.selectedLine ? 3 : 1,

            areaStyle:
              key === this.selectedLine
                ? {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: this.isNewHome ? '#03B6FF' : this.componentConfig.chartColors[index], // color at 0%
                        },
                        {
                          offset: 1,
                          color: '#ffffff', // color at 100%
                        },
                      ],
                      global: false, // default is false
                    },

                    // color: '#03B6FF',
                    opacity: this.isNewHome ? 0.2 : 0.1,
                  }
                : { color: 'none' },
          };

          if (this.showOneLine && key === this.selectedLine) {
            lines.push(line);
          } else if (!this.showOneLine) {
            lines.push(line);
          }
          return lines;
        }, []);
      }

      const allValues = this.chartData.slice(1);
      let allValuesMerged = [];
      for (const data of allValues) {
        allValuesMerged = [...allValuesMerged, ...data];
      }
      const maximumValue = Math.max(...allValuesMerged);

      // y-axis
      cfg.yAxis = this.lineSeriesKeys.reduce((lines: object[], key: string) => {
        lines.push({
          show: key === this.selectedLine,
          max: !this.isNewHome ? maximumValue : null,
          type: 'value',
          splitLine: {
            show: false,
          },
          scale: !!this.componentConfig.scale,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
            color: textColor,
            padding: [0, 0, 0, 0],
            formatter: function (value) {
              try {
                if (key === 'CompletionRate') {
                  // Add perecent to completion rate.
                  return `${value}%`;
                }
                if (typeof value === 'number') {
                  return utils.formatNumberWithCommas(value); // .toLocaleString();
                }
                return value;
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('xaxislabel formatter', exp, value);
              }
              return value;
            },
          },
        });
        // console.log('lines are', lines);
        return lines;
      }, []);

      // legend keys
      const legendKeys: string[] = [];
      const keys = this.componentConfig.dynamicLineKeys ? this.dynamicLineKeys : this.componentConfig.lineSeriesKeys;
      keys.forEach((key: string) => {
        legendKeys.push(this.getLegendKeyName(key));
      });

      const formattedLegendsKey = this.componentConfig?.legendKeys;
      const isNewHome = this.isNewHome;

      cfg.legend = {
        show: !this.componentConfig?.hideLegend,
        data: legendKeys,
        selectedMode: !this.componentConfig.hideLegend,
        icon: 'circle',
        bottom: 0,
        left: '3%',
        textStyle: {
          fontWeight: 600,
        },
        formatter: function (name) {
          if (!isNewHome) return name;
          else {
            const index = legendKeys.indexOf(name);
            return index !== -1 ? formattedLegendsKey[index] : name;
          }
        },
      };

      return cfg;
    },
    colorset(): string[] {
      const colors: string[] = [];
      const greyColors = ['#c6c6c6', '#5f5e5e', '#a6a6a6', '#000000', '#ff0000'];
      let greyColorIndex = 0;
      const keys = this.componentConfig.dynamicLineKeys ? this.dynamicLineKeys : this.componentConfig.lineSeriesKeys;
      const colorIndex = keys.indexOf(this.selectedLine);
      let colorArray = this.Theme.config.chartColors;
      if (this.componentConfig.chartColors) {
        // use configurability override if defined
        colorArray = this.componentConfig.chartColors;
      }

      let lineColor = colorArray[colorIndex] || '#1e5380';
      if (this.componentConfig.chartColorIndex) {
        // a way to override color from layout config
        lineColor = colorArray[this.componentConfig.chartColorIndex];
      }

      // we need to look at the computed lineSeriesKeys for web
      this.lineSeriesKeys.forEach((key: string) => {
        if (!this.showOneLine && key !== this.selectedLine) {
          const grey = this.dynamicLineKeys ? '#A2A2A2' : greyColors[greyColorIndex];
          colors.push(grey);
          greyColorIndex += 1;
        } else colors.push(lineColor);
      });

      return colors;
    },
    tweaks(): string[] {
      if (!this.componentConfig.commonTweaks) {
        return [];
      }
      if (Array.isArray(this.componentConfig.commonTweaks)) {
        return this.componentConfig.commonTweaks;
      }
      return [this.componentConfig.commonTweaks];
    },
    chartClass(): string {
      let css = 'generic-line-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      return css;
    },
    isMultiLine(): boolean {
      return this.lineSeriesKeys.length > 1;
    },
    sideMetricsConfig(): object | null {
      return null;
    },
    showOneLine(): boolean {
      return this.componentConfig.showOneLine;
    },
    chartColors(): boolean {
      return this.componentConfig.chartColors;
    },
    breakpoints(): boolean {
      return true;
      // return this.componentConfig.breakpoints;
    },
  },
  methods: {
    totalCount(total): string | null {
      if (typeof total !== 'number') {
        return null;
      }
      return utils.formatNumberWithCommas(total);
    },
    legendSingleColor(key): string {
      const keys = this.componentConfig.dynamicLineKeys ? this.dynamicLineKeys : this.componentConfig.lineSeriesKeys;
      const colorIndex = keys.indexOf(key);
      let colorArray = this.Theme.config.chartColors;
      if (this.componentConfig.chartColors) {
        // use configurability override if defined
        colorArray = this.componentConfig.chartColors;
      }

      let lineColor = colorArray[colorIndex] || '#1e5380';
      if (this.componentConfig.chartColorIndex) {
        // a way to override color from layout config
        lineColor = colorArray[this.componentConfig.chartColorIndex];
      }
      return lineColor;
    },
    setChartData(name: string, requestData = false): void {
      if (this.dynamicLineKeys && requestData) {
        this.$emit('change-data-period', name.toLowerCase());
        return;
      }
      const fieldToSearch = this.isNewHome ? 'name' : 'title';
      const metric = this.metrics.find(metric => name === metric[fieldToSearch] && metric.chartData?.length);
      if (metric) {
        if (this.withTracking) {
          this.$emit('track-selected-metric', metric?.name);
        }
        this.chartData = metric.chartData;
        this.toggle_chart = metric.name;
        this.chosenMetric = metric;
        this.initChart();
      } else return;
    },
    initChart(): void {
      if (this.loading) return;
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      try {
        setTimeout(() => {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          this.chartInstance = ECharts.init(this.$refs.canvas);
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const c = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              c.on('finished', () => {
                this.$emit('rendered', { empty: false });
              });
              c.on('rendered', () => {
                this.$emit('rendered', { empty: false });
              });
            }
            c.setOption(this.chartConfig, true);
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        }, 10);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('initChart', error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
    getLegendKeyName(key: string): string {
      let name;
      if (this.componentConfig.dynamicLineKeys) {
        return key === 'all' ? 'All Impressions' : utils.tacticTitleMap(key);
      }
      switch (key) {
        case 'Impressions':
          name = key;
          break;
        case 'Hours':
          name = 'Viewing Hours';
          break;
        case 'ClickThrough':
          name = `CTR`;
          break;
        case 'CompletionRate':
          name = `VCR`;
          break;
        case 'GeoRetargetingImpressions':
          name = `Retargeting Imps`;
          break;
        case 'GeoBehaviorImpressions':
          name = `Behavioral Imps`;
          break;
        case 'GeoCookieImpressions':
          name = `Historical Imps`;
          break;
        case 'GeoFencingImpressions':
          name = `Geofencing Imps`;
          break;
        case 'GeoVisits':
          name = `Store Visits`;
          break;
        default:
          name = utils.headerNamesMap(key);
          break;
      }
      return name;
    },
    selectLine(value: string): void {
      if (value !== this.selectedLine) {
        this.analyticTrack(
          this.trackValue.ORDERS_SELECT_PRODUCT_DELIVERED_IMPS,
          `Product: ${value}, Order ID: ${this.$route.query?.orderId}`,
        );
        this.selectedLine = value;
        this.initChart();
      }
    },
    getTableData(): void {
      let headers: string[] = [];
      if (this.componentConfig.exportTweaks?.addDayOfTheWeekColumn) {
        headers.push('Day');
      } else {
        headers.push('n/a'); // can we know?
      }
      const keys = this.componentConfig.dynamicLineKeys ? this.dynamicLineKeys : this.componentConfig.lineSeriesKeys;
      headers = [...headers, ...keys];

      // loop over chartData[0], those are the x axis values
      const data = [];
      if (Array.isArray(this.chartData) && Array.isArray(this.chartData[0])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.chartData[0].forEach((key: any, index: number) => {
          const row = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.chartData.forEach((serie: any[]) => {
            const val = serie[index];
            if (typeof val === 'string') {
              row.push(val.trim());
            } else {
              row.push(val);
            }
          });
          data.push(row);
        });
      }
      // console.log('line chart export', headers, data);
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
  },
});
